import styled, { css } from 'styled-components';

import { Button, TextButton } from '@xing-com/button';
import {
  spaceS,
  spaceL,
  spaceXL,
  spaceXS,
  spaceXXS,
  xdlColorBorderSoft,
} from '@xing-com/tokens';

type NoMargin = { $noMargin?: boolean };
type ActionButtonProps = { $isBottom?: boolean; $isTop?: boolean } & NoMargin;
type ButtonProps = { $isDelete?: boolean } & NoMargin;

const getBottomButtonCss = (noMargin = false): ReturnType<typeof css> => css`
  justify-content: flex-start;
  border-top: 1px solid ${xdlColorBorderSoft};
  margin-top: ${noMargin ? 0 : spaceXS};
  padding-top: ${spaceS};
`;

const getTopButtonCss = (): ReturnType<typeof css> => css`
  position: absolute;
  top: ${spaceL};
  right: ${spaceL};
`;

const buttonStyles = ({
  $isDelete,
  $noMargin,
}: ButtonProps): ReturnType<typeof css> => css`
  ${$isDelete &&
  !$noMargin &&
  css`
    margin-left: ${spaceXL};
  `}

  & span:last-of-type {
    margin-top: ${spaceXXS};
  }
`;

export const ActionButtons = styled.div<ActionButtonProps>`
  display: flex;
  justify-content: flex-end;
  ${({ $isBottom, $isTop, $noMargin }) =>
    $isBottom && !$isTop && getBottomButtonCss($noMargin)}
  ${({ $isBottom, $isTop }) => !$isBottom && $isTop && getTopButtonCss()}

  @media print {
    display: none;
  }
`;

export const BottomTextButton = styled(TextButton)<ButtonProps>`
  ${({ $isDelete, $noMargin }) => buttonStyles({ $isDelete, $noMargin })}
`;

export const BottomButton = styled(Button)<ButtonProps>`
  ${({ $isDelete, $noMargin }) => buttonStyles({ $isDelete, $noMargin })}
`;
