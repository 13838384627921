import { useQuery } from '@apollo/client';
import { useRef, useEffect, useState } from 'react';

import type { MatchingReasonsResult } from '@xing-com/crate-common-graphql-types';
import { FabiBackgroundJobStatus } from '@xing-com/crate-common-graphql-types';
import { JobMatchingReasonsQueryDocument } from '@xing-com/crate-jobs-graphql';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useJobMatchingReasons = (
  backgroundJobId?: MatchingReasonsResult['backgroundJobId']
) => {
  const pollingTimeoutRef = useRef<number | null>(null);
  const [forcedPollingError, setForcedPollingError] = useState<boolean>(false);

  const {
    data,
    loading,
    stopPolling,
    called: hasPollingStarted,
  } = useQuery(JobMatchingReasonsQueryDocument, {
    variables: { backgroundJobId: backgroundJobId ?? '' },
    skip: !backgroundJobId,
    pollInterval: 1000,
  });

  useEffect(() => {
    if (hasPollingStarted) {
      pollingTimeoutRef.current = window.setTimeout(() => {
        setForcedPollingError(true);
        stopPolling();
      }, 6000);
    }

    return () => {
      if (pollingTimeoutRef.current) {
        window.clearTimeout(pollingTimeoutRef.current);
        pollingTimeoutRef.current = null;
      }
    };
  }, [hasPollingStarted, stopPolling]);

  useEffect(() => {
    const shouldStopPolling =
      data &&
      data.fabiJobSearchMatchingReasonsPoll?.status !==
        FabiBackgroundJobStatus.InProgress;

    if (shouldStopPolling) {
      stopPolling();

      if (pollingTimeoutRef.current) {
        clearTimeout(pollingTimeoutRef.current);
        pollingTimeoutRef.current = null;
      }
    }
  }, [data, stopPolling]);

  if (forcedPollingError) {
    return {
      loading: false,
      reasons: [],
      // This error will never be displayed as we have a default error message
      error: 'Timeout error',
    };
  }

  return {
    loading,
    reasons:
      data?.fabiJobSearchMatchingReasonsPoll?.result?.matchingReasons ?? [],
    error: data?.fabiJobSearchMatchingReasonsPoll?.errorMessage,
  };
};

export type UseJobMatchingReasonsResult = ReturnType<
  typeof useJobMatchingReasons
>;
